/* NotiStack Overrides */
.notistack-MuiContent-success {
  background-color: var(--tealColor) !important;
}

.notistack-MuiContent-error {
  background-color: var(--redColor) !important;
}

.notistack-MuiContent-warning {
  background-color: var(--yellowColor) !important;
  color: black !important;
}

/* MUI Overrides */
/* Card Override */
.MuiPaper-root {
  border-radius: var(--borderRadius) !important;
}

/* Data Grid Override */
.MuiDataGrid-columnHeaderTitle {
  font-weight: bold !important;
  overflow: visible !important;
  -webkit-font-smoothing: antialiased;
}

.MuiDrawer-paperAnchorDockedLeft {
  border-right: 0px !important;
}

/* Full Calendar Override */
.fc-day-today {
  background-color: #c8feff !important;
}

.fc-scroller {
  height: auto !important;
}

.fc-head .fc-widget-header {
  margin-right: 0 !important;
}

.fc-scroller {
  overflow: visible !important;
}

.fc .fc-scroller-liquid-absolute {
  position: inherit;
}

.css-160unip {
  padding-top: 0px !important;
  overflow: visible !important;
}
