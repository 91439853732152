.deleteModal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--whiteColor);
  width: 40% !important;
  border-radius: 10px;
  border: none;
  border-top: 32px var(--redColor) solid;
  padding-top: 0 !important;
  padding: .25rem;
}

.deleteModalSuccess {
  border-top: 32px var(--tealColor) solid !important;
}

.deleteActions {
  padding: 10px;
  padding-right: 24px;
  display: flex;
  justify-content: flex-end;
}

.modalContent {
  display: flex;
  align-items: center;
}

.deleteIcon {
  font-size: 90px;
  color: var(--redColor);
  margin-right: 24px;
}

.modalIconTeal {
  font-size: 90px;
  color: var(--tealColor);
  margin-right: 24px;
}
