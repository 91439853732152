body {
  margin: 0;
  font-family: "Roboto", "Helvetica", "Arial", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.paperContent{
  padding: 20px;
  height: calc(100vh - 140px);
  overflow-y: auto;
}

@media (max-width: 600px){
  .paperContent{
    padding: 10px;
    width: calc(100% - 20px);
    height: calc(100vh - 148px);
  }
}

.customScrollbar::-webkit-scrollbar {
	width: 10px;
	background-color: #F5F5F5;
}
.customScrollbar::-webkit-scrollbar-track {
  border-radius: 10px;
  background: rgba(0,0,0,0.1);
  border: 1px solid #ccc;
}

.customScrollbar::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: linear-gradient(to left, #fff, #e4e4e4);
  border: 1px solid #aaa;
}

.customScrollbar::-webkit-scrollbar-thumb:hover {
  background: #fff;
}

.customScrollbar::-webkit-scrollbar-thumb:active {
  background: linear-gradient(to left, #46b9bc, #0ae7ec);
}