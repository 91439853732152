#dining-btn {
  color: rgba(0, 0, 0, .87);
  background-color: #e0e0e0;
  font-size: .875rem;
  font-weight: bold;
}

#dining-btn:hover {
  background-color: #c5c5c5;
}

.resident-name {
  font-size: 2rem;
  line-height: 2.5rem;
  margin-top: .125rem;
}