.order-tab {
  color: #c8eaeb !important;
  font-weight: bold !important;
}

.MuiTab-root.Mui-selected {
  color: white !important;
}

.MuiBadge-badge {
  background-color: #F50057 !important;
  height: 1.375rem !important;
  width: 1.375rem !important;
  border-radius: 50% !important;
  transform: translate(1.75rem, -.75rem) !important;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
}

.MuiBadge-badge.MuiBadge-invisible {
  display: none;
}