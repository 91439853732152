.general-text {
  font-size: 1rem;
}

.MuiTableRow-root {
  height: 3rem;
}

.MuiTableCell-root {
  line-height: 1 !important;
  padding: 0 1rem !important;
}

.MuiMenuItem-root.Mui-selected {
  background-color: rgba(0, 0, 0, .14) !important;
  color: black !important;
}

.MuiCheckbox-root.Mui-checked:has(#orderCheckbox) {
  color: #f50057 !important;
}

#alt-accordion.MuiAccordion-root:first-of-type {
  border-top-left-radius: .5rem !important;
  border-top-right-radius: .5rem !important;
}

#alt-accordion.MuiAccordion-root:last-of-type {
  border-bottom-left-radius: .5rem !important;
  border-bottom-right-radius: .5rem !important;
}

.warning {
  color: #F0c63E;
  font-size: 1.25rem;
  margin-right: .25rem;
}

.noteTextarea {
  border: 1px solid #F0C63E !important;
  border-radius: .375rem !important;
  margin-bottom: .25rem !important;
}

.MuiInputBase-root:has(#textarea):hover fieldset {
  border: none !important;
}

.addlCharge {
  border: 1px solid #F0C63E !important;
  border-radius: .25rem !important;
  height: 1.5rem;
  font-size: .875rem;
  padding: .25rem .5rem;
}

.addlCharge:focus {
  outline: unset !important;
}