.MuiClockNumber-root.Mui-disabled {
  display: none;
}

.mealContainer {
  overflow-y: scroll;
}

.tableCell {
  padding: 4px 10px 4px 10px;
  min-width: 5.5rem;
}
